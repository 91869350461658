.taskAddModal {
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0;

    & > * {
      flex-grow: 1;
    }
  }
}