.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }

  &__title {

  }

  &__menu {
    display: flex;
    text-align: center;

    .profile {
      margin-right: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #1982F4;
    }
  }
}
