.appContainer {
  height: 100vh;
  padding: 96px 16px 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: auto;

  @media screen and (min-width: 1440px)  {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }
}